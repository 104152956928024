@use "@carbon/react";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

$black: #2e2e2e;
* {
  scrollbar-width: thin;
  scrollbar-color: #0065ff white;
}
iframe {
  scrollbar-width: thin;
  scrollbar-color: #0065ff white;
  width: 100% !important;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
// .userAddModal input {
//  autoc: off;
//   -webkit-autofill: off;
// }

*::-webkit-scrollbar-track {
  background: white;
}
// * {
//   box-sizing: content-box;
// }
/* Custom CSS for aligning the close button */
// .userAddModal .bx--modal-close {
//   top: 15px; /* Adjust the top margin to ensure it's aligned */
//   right: 15px; /* Adjust the right margin to align horizontally */
//   position: absolute; /* Make sure the close button is absolutely positioned */
//   z-index: 1; /* Ensure the close button is above other modal elements */
// }

// .userAddModal .bx--modal-close svg {
//   width: 160px; /* Adjust size of the close icon */
//   height: 16px; /* Adjust size of the close icon */
// }


*::-webkit-scrollbar-thumb {
  background-color: #0065ff;
  border-radius: 20px;
  border: 3px solid white;
}
html,
body {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0 !important;  /* scroll thumb and track */
}
iframe {
  width: 100vw;
  height: 100vh;
}
// SignUp Section
.bg {
  background-color: #0065ff;
  height: 100vh;
  width: 100vw;
}
.sb-tooltip-trigger {
  background: none;
  border: none;
}
.toggle-container3 {
  padding-bottom: 0;
  padding-top: 10px;
}
.pd {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}
.pdt {
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-align: center;
  img {
    height: 80vh;
  }
}
.cont {
  padding-left: 30px;
}
.cont1 {
  padding-top: 20px;
  width: 40%;
}
.txtc {
  padding-bottom: 8px;
}
.txtcc {
  padding-top: 8px;
}
.txtcc {
  padding-top: 12px;
}
//Signup Section ends
// * {
//   box-sizing: none;
// }

//Onboard Section
.onbUserProfileBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 20px 0px 0px 0px;
  // padding-left: 20px;
  div {
    padding-top: 12px;
  }
}
.onbBtn {
  button {
    margin-right: 8px;
    margin-top: 15px;
  }
}
.rdxs{
  line-height: 180%;
}
.cds--modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0px;
  padding-top: 0px;
}
.onPScroll {
  .onpInput {
    .onin {
      padding-top: 12px;
      width: 90%;
      padding-left: 24px;
    }
    .oninn {
      padding-top: 12px;
      width: 90%;
      padding-left: 24px;
    }
  }
}
.cds--modal-footer .cds--btn{
  font-size: 18px;
}
.cds--btn--secondary{
  background-color: #2B2B2B;
  
}
.cds--btn--secondary:hover {
  background-color: #1A1A1A; /* Slightly darker for hover effect */
}

.xcvbv {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.onBScroll {
  margin-top: 24px;
  height: 70vh;
  overflow: hidden;
  background-color: #fafafa;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
}
.onbInput {
  padding-top: 24px;
  padding-bottom: 48px;
  // margin-right: -50px; /* Maximum width of scrollbar */
  padding-right: 24px;
  height: 100%;
  overflow-y: scroll;
  // -ms-overflow-style: none; /* Internet Explorer 10+ */
  // scrollbar-width: none; /* Firefox */
  // ::-webkit-scrollbar {
  //   width: 0; /* Remove scrollbar space */
  //   background: transparent; /* Optional: just make scrollbar invisible */
  //   display: none !important;
  // }
  .oninn {
    padding-top: 24px;
    display: flex;
    width: 100%;
    padding-left: 24px;
    div {
      margin-right: 24px;
    }
    .on25 {
      width: 30%;
      div {
        width: 100%;
      }
    }
    .on75 {
      width: 75%;
      div {
        width: 100%;
      }
    }
  }
  .onin {
    padding-top: 24px;
    width: 100%;
    padding-left: 24px;
  }
}
.onbar {
  padding-top: 24px;
  background-color: #fafafa;
  padding-bottom: 48px;
  // margin-right: -50px; /* Maximum width of scrollbar */
  padding-right: 50px;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: thin; /* Firefox */
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
    display: none !important;
    
  }
  .card {
    background-color: #fff;
    padding: 40px;
    box-shadow: 2px 3px 9px rgba(169, 169, 169, 0.24);
    margin-bottom: 16px;
    margin-left: 40px;
    .options {
      padding-top: 24px;
      .opt {
        cursor: pointer;
        margin-top: 8px;
        background-color: #f5f5f5;
        padding: 8px;
        width: 100%;
      }
    }
    .bar {
      width: 100%;
      margin-top: 40px;
      border: 1px solid #ebebeb;
    }
  }
}
.vbnm {
  height: 80vh;
  display: flex;
}

.cds--data-table thead {
  position: sticky;
  top: 0px;
}
.onbar .card .options .opt.selected {
  background: #0f62fe;
}

.onbar .card .options .opt.selected span {
  color: #ffffff;
}
.cds--btn--primary {
  font-size: 18px !important;
}
.onbHead {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;
}
.onbh {
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 3px 9px rgba(169, 169, 169, 0.24);
}
.onboards {
  background-color: #fafafa;
  height: 100vh;
}
//onBoard Section Ends here

//Dashboard Starts here
//Index Starts here

.custom_container {
  width: 1000px;
  margin-left: 280px;
}
.compareLogo{
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.ptl {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
}
.ptlll {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
  .report{
    .bgf{
      justify-content: center !important;
      .barCOmpare{
        width: 98% !important;
      }
    }
  }
}
.innerPortal {
  padding-top: 130px;
  padding-left: 280px;
  padding-right: 30px;
}
.cardHelper {
  background-color: #fff;
  min-height: 150px;
  width: 100%;
  margin-bottom: 24px;
  .progressOnb {
    width: 100%;
    padding: 40px;
  }
  .help {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    .cyt {
      img {
        height: 25px;
        width: auto;
      }
    }
  }
}
.cardFullDashboard {
  background-color: #fff;
  min-height: 350px;
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 24px;
  button {
    margin-bottom: 16px;
  }
  .progressOnb {
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .viesw {
    padding-left: 32px;
    padding-right: 32px;
  }
  .lty {
    display: flex;
    align-items: center;
  }
  .txtCardFullDashs {
    padding-right: 16px;
    padding-top: 32px;
    div {
      padding-bottom: 12px;
    }
    button {
      margin-top: 32px;
    }
  }
  .txtCardFullDash {
    padding-right: 16px;
    padding-left: 32px;
    padding-top: 32px;
    div {
      padding-bottom: 12px;
    }
    button {
      margin-top: 32px;
    }
  }
}
.quarterArea {
  display: flex;
  justify-content: space-between;
  .cardQuarterDashboard {
    width: 49%;
    min-height: 570px;
    background-color: #fff;
    margin-bottom: 20px;
  }
}
.planCard {
  padding: 20px;
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .headerPlan {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
    span {
    }
  }
  .contentCard {
    display: flex;
    align-items: center;
    min-height: 250px;
    margin-top: 10px;
    div {
      padding-left: 24px;
      width: 50%;
    }
  }
}
.assesment-btn {
  background: #e6f0ff;
  border: 0;
  outline: none;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.16px;
  color: #0065ff;
  padding: 12px 0;
  width: 100%;
}
.mnb {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  // align-items: center;
}
.userCard {
  padding: 20px;
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .headerPlan {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
    span {
      padding-left: 8px;
    }
  }
  .contentCard {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0;
    div {
      width: 100%;
    }
  }
}
.lda {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes ldio-zz0p9m9nr8 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-zz0p9m9nr8 div {
  left: 183.77px;
  top: 93.84px;
  position: absolute;
  animation: ldio-zz0p9m9nr8 linear 1.8518518518518516s infinite;
  background: #0065ff;
  width: 23.46px;
  height: 46.92px;
  border-radius: 11.73px / 23.46px;
  transform-origin: 11.73px 101.66px;
}
.ldio-zz0p9m9nr8 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.6666666666666665s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(2) {
  transform: rotate(36deg);
  animation-delay: -1.4814814814814814s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(3) {
  transform: rotate(72deg);
  animation-delay: -1.2962962962962963s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(4) {
  transform: rotate(108deg);
  animation-delay: -1.111111111111111s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(5) {
  transform: rotate(144deg);
  animation-delay: -0.9259259259259258s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(6) {
  transform: rotate(180deg);
  animation-delay: -0.7407407407407407s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(7) {
  transform: rotate(216deg);
  animation-delay: -0.5555555555555555s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(8) {
  transform: rotate(252deg);
  animation-delay: -0.37037037037037035s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(9) {
  transform: rotate(288deg);
  animation-delay: -0.18518518518518517s;
  background: #0065ff;
}
.ldio-zz0p9m9nr8 div:nth-child(10) {
  transform: rotate(324deg);
  animation-delay: 0s;
  background: #0065ff;
}
.loadingio-spinner-spinner-vxwqtf5wpze {
  width: 391px;
  height: 391px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-zz0p9m9nr8 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-zz0p9m9nr8 div {
  box-sizing: content-box;
}

.vcb {
  text-align: center;
  justify-content: center;
}
.compCard {
  display: flex;
  .lti {
    width: 100%;
  }
  .selectSpace {
    padding-top: 25px;
    width: 100%;
    .mtuselect {
    }
  }
}
.loaderscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 94vh;
  padding-left: 250px;
}
.rmsc {
  max-width: 1000px;
}
.xcgz {
  background-color: #fff;
}
.bgf {
  display: flex;
  justify-content: center;
  .barCOmpare {
    border-radius: 15px;
    padding: 40px;
    width: 80%;
    height: auto;
    background-color: #fff;
    .chartContainer {
      min-width: 600px;
    }
  }
}
.cds--btn-set .cds--btn:not(:focus) {
  box-shadow: none;
}
.cds--btn-set .cds--btn:not(:focus) {
  box-shadow: none;
}

.cfd {
  li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  li:before {
    content: "•";
    display: flex;
    justify-content: flex-end;
    // flex: 0 0 .6em;
    // line-height: .4em;
    padding-right: 0.5em;
  }
}
.consultError {
  padding-top: 100px;
  text-align: center;
  padding-bottom: 200px;
}
.highlightCard {
  border: solid #0065ff 2px;
}
.consultCard {
  padding: 20px;
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .headerPlan {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
    span {
      padding-left: 8px;
    }
  }
  .contentCard {
    display: flex;
    align-items: center;
    min-height: 250px;
    div {
      padding-left: 24px;
      width: 50%;
    }
  }
}
.ghjk{
  display: flex;
  flex-direction: column;
  align-items: normal;
  margin-bottom: 10px;
  justify-content: end;
}
.cds--btn.cds--skeleton{
  background-color: #0065ff;
  color: #fff;
}
.fghz {
  display: flex;
  .fg {
    margin-right: 24px;
  }
  button {
    max-width: 48%;
    width: 48%;
  }
}
.peson-add-list {
  padding-left: 0 !important;
}

.peson-add-list b {
  display: block;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #2e2e2e;
}

.peson-add-list .add-btn {
  border: 1px solid #ebebeb;
  outline: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 6px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: #0065ff;
  margin-top: 12px;
  background-color: #ffffff;
}

.peson-add-list .added-user {
  border: 1px solid #ebebeb;
  outline: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 16px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: #0065ff;
  margin-top: 12px;
  background-color: #ffffff;
}

.peson-add-list .added-user img {
  margin-right: 15px;
}

.peson-add-list .added-user .added-user-info {
}
.peson-add-list .added-user .added-user-info h5 {
  text-align: left;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #525252;
}
.peson-add-list .added-user .added-user-info p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  color: #a3a3a3;
}

.peson-add-list .add-btn svg {
  margin-right: 15px;
  background: #e6f0ff;
  border-radius: 50%;
}
.assTab {
  padding-top: 24px;
}
.asstabtable {
  padding-top: 24px;
}

.PortalLayout {
  width: 250px;
  position: fixed;
  height: 100vh;
  background-color: #fff;
  border: 1px solid #dedede;
  .Playout {
    padding: 20px;
    .services {
      padding-top: 10px;
      .usa {
        cursor: pointer;
        .userPro {
          background-color: #e6f0ff;
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          .usp {
            display: flex;

            .userMargin {
              margin-left: 16px;
              div {
                margin-left: 16px;
              }
              border-radius: 50px;
              height: 48px;
            }
          }
        }
      }
      .serve {
        .ser {
          padding: 16px 10px;
          display: flex;
          cursor: pointer;
          img {
            padding: 4px;
          }
          svg {
            width: 30px;
            path {
              stroke: #4d93ff;
            }
          }
          span {
            padding: 4px;
          }
          div {
            display: flex;
            align-items: center;
          }
        }
        .serActive {
          background: #e6f0ff;
          svg {
            path {
              stroke: #4d93ff;
            }
          }
        }
        .liness {
          margin-top: 16px;
          border: 1px solid #bfbfbf;
          width: 100%;
        }
      }
      .servess {
        padding-left: 12px;
        .ser {
          padding-top: 16px;
          padding-bottom: 16px;
          display: flex;
          img {
            padding: 4px;
          }
          span {
            padding: 4px;
          }
          div {
            display: flex;
            align-items: center;
          }
        }
        .liness {
          margin-top: 16px;
          border: 1px solid #bfbfbf;
          width: 100%;
        }
      }
    }
    .lgSec {
      .liness {
        border: 1px solid #bfbfbf;
        width: 100%;
      }
      .lgo {
        padding-bottom: 10px;
      }
    }
  }
}
.portalHeader {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  height: 80px;
  width: calc(100% - 250px);
  position: fixed;
  right: 0;
  z-index: 99;
  .textHeader {
    padding-top: 24px;
    padding-left: 40px;
  }
  .icn {
    padding-top: 24px;
    padding-right: 40px;
  }
}
.userInfoFixed {
  padding-top: 160px;
  display: flex;
  .profileInfo {
    padding-top: 28px;
    .type {
      background-color: #ebebeb;
      padding: 4px 8px;
      width: fit-content;
      border-radius: 24px;
      margin-top: 6px;
    }
  }

  .profileImage {
    label {
      div {
        overflow: hidden;
        width: fit-content;
        border-radius: 50%;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.4) 22.5%,
          rgba(0, 0, 0, 0) 34.69%,
          rgba(0, 0, 0, 0) 180%
        );
        img {
          border-radius: 50%;
          height: 140px;
          width: auto;
        }
      }
    }
  }
}
.userTabs {
  padding-top: 60px;
}
.cds--tabs .cds--tabs__nav-item--selected {
  color: #0f62fe;
  background-color: #e6f0ff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.cds--tabs .cds--tabs__nav-item + .cds--tabs__nav-item {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.cds--tabs .cds--tabs__nav-link:focus {
  outline: none;
}
.cds--toggle__switch--checked {
  background-color: var(--cds-support-success, #0065ff);
}

.chatviewMain {
  height: 87vh;
  background-color: #fff;
  border-radius: 25px;
  .chatHeader {
    padding: 24px;
    height: 8vh;
    .lnx {
      height: 1px;
      width: 100%;
      background-color: #0065ff;
    }
  }
  .ChatBody {
    height: 60vh;
    padding-top: 24px;
    overflow-y: scroll;
    .panMessage {
      padding: 8px;
      margin: 0px 30px 10px 20px;
      background-color: #fff;
      border-radius: 8px;
      color: #000;
      .cds--tag--outline{
        background-color: #0065ff;
        color: #fff;
      }
    }
    .usMessage {
      padding: 8px;
      margin: 0px 30px 10px 20px;
      background-color: #bfbfbf;
      border-radius: 8px;
      color: #000;
    }
    .ChatLoad {
      padding-top: 8vh;
    }
  }
  .chatinput {
    width: 100%;
    padding: 24px;
    display: flex;
    .txtre {
      width: 90%;
      padding-right: 16px;
    }
  }
}
.mbls {
  display: none;
}
.cds--text-area__wrapper {
  textarea {
    width: 100%;
  }
}
.cds--tab-content:focus {
  outline: none;
}
.bg-grey-plan {
  background-color: #f5f5f5;
  min-height: 100vh;
}
.cds--text-input {
  font-size: 18px !important;
}
.cds--select-input {
  font-size: 18px !important;
}
.cds--checkbox-label-text {
  font-size: 14px !important;
}
.plan-txt2 {
  padding-top: 32px;
  justify-content: space-between;
}
.cds--form-requirement {
  font-weight: 600 !important;
}
.txtccc {
  padding-top: 16px;
  .ghg {
    padding-top: 16px;
  }
}
.tablebtnspace {
  padding-right: 24px;
}
.plancta {
  padding-top: 100px;
  display: flex;
}
.planctaCard {
  padding-right: 24px;
}
//Assesment Section Starts Here
.assesment {
  min-height: 102vh;
  width: 100%;
  background-color: #f5f5f5;
}
.assesmentBottom {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 1.21842px 1.82763px 12px rgba(169, 169, 169, 0.6);
  position: relative;
}
.assesmentBottom span {
  display: block;
  position: absolute;
  height: 4px;
  left: 0;
  top: 0;
  background: #0065ff;
}
.progressOnb {
  padding-bottom: 20px;
}
.assesmentbottomleft {
  width: 50%;
}

.assesmentbottomleft h4 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #0d0d0d;
  margin-bottom: 7px;
}

.assesmentbottomleft p {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  color: #666666;
}

.assesmentbottomright {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.assesmentbottomright button {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  text-align: center;
  letter-spacing: 0.16px;
  border: 0;
  padding: 11px 8px;
}
.notAllow {
  cursor: not-allowed !important;
}
.saveandclose {
  color: #0f62fe;
  background: #e6f0ff;
}

.save {
  color: #ffffff;
  background: #0f62fe;
  width: 112px;
  cursor: pointer;
}
.assesmentUser {
  display: flex;
  padding-top: 8px;

  .user {
    border-radius: 50px;
    height: 30px;
  }
}
.btnss {
  background: #e6f0ff;
  color: #0065ff;

  border: 2px solid #e6f0ff;
}
.btnss:focus {
  border: 0;
  box-shadow: none;
}
.assesmentHeader {
  width: 100vw;
  height: 80px;
  background-color: #fff;
  position: fixed;
  padding-top: 20px;
  z-index: 10;
}
.assesmentqsection {
  padding-top: 68px;
  padding-bottom: 100px;
  .assMentQcard {
    margin-top: 12px;
    background-color: #fafafa;
    .ques {
      padding: 40px 40px 32px 40px;
    }
    .bdr {
      border: 1px solid #ebebeb;
      padding-left: 40px;
      padding-right: 40px;
    }
    .qsbtn {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 20px;
      padding-bottom: 40px;
      display: flex;
      .qsbt {
        padding-right: 20px;
      }
    }
  }
}

.assesmentqsection .assMentQcard .qsbtn .qsbt .btnss.selected {
  background: #0065ff;
  color: #ffffff;
}

.Qcards {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.btnca {
  background-color: #ffffff;
  color: #0d0d0d;
}
.customBtnL {
  background-color: #e6f0ff;
  height: 32px;
  color: #0065ff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border: none;
  padding: 4px;
  cursor: pointer;
  width: 100%;
}
.customBtnD {
  background-color: #0f62fe;
  height: 32px;
  color: #fff;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border: none;
  padding: 4px;
  cursor: pointer;
  width: 100%;
}
.assesmentCard {
  padding-top: 80px;
  width: 260px;
  position: fixed;
  .segment {
    height: max-content;
    padding-top: 200px;
    background-color: #fff;
    padding-bottom: 20px;
    .warnText {
      padding-top: 8px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .buttonSeg {
      padding-top: 40px;
      display: flex;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
      .btnspace {
        padding-right: 4px;
        width: 100%;
      }
    }
    .seg {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 24px;
    }
    .segActive {
      border-left: 4px solid #0065ff;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 20px;
    }
    .segActiveSel {
      border-left: 4px solid #0065ff;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 20px;
      background-color: #e6f0ff;
    }
  }
  .assesmentCardimg {
    width: 260px;
    position: fixed;
    height: 200px;
    .asscardText {
      position: fixed;
      z-index: 1;
      padding: 20px;
      width: 260px;
      top: 80px;
    }
    img {
      height: 200px;
      width: 260px;
      position: fixed;
    }
  }
}
.cancelled-icon {
  color: #dc3545; /* Red color for the cancelled icon */
  font-size: 64px;
}
.payment-success-container {
  padding-top: 7%;
  text-align: center;
  margin: 50px auto;
}

.success-icon {
  color: #28a745; /* Green color for the tick icon */
  font-size: 64px;
}
.xczxzz {
  position: absolute;
  z-index: 1;
  right: 0.8%;
  width: 35px;
  height: 35px;
  bottom: -30%;
  cursor: pointer;
  div {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-color: #0065ff;
  }
}
.featured-plan {
  padding: 100px 0;
  overflow-x: scroll;
  .featured-top {
    .featured-header {
      padding: 0 20px;
      .heading {
        margin-bottom: 18px;
        display: block;
      }
    }
  }
  .featured-plans {
    display: flex;
    justify-content: space-between;

      .featured-header {
        padding: 0 20px;
        min-width: 303px;
        max-width: 303px;
        .heading {
          margin-bottom: 18px;
          display: block;
        }
      }
    .single-plan {
      position: relative;
      background: #ffffff;
      min-width: 303px;
      margin-left: 12px;
      padding: 16px 20px;
      height: 350px;
      max-width: 303px;
      .plan-heading {
        font-family: "Ubuntu";
        font-style: normal;
        font-size: 24px;
        line-height: 34px;
        color: #4b4b4b;
        margin-bottom: 5px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .tra {
          height: 12 0px;
        }
      }
      p {
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 120%;
        color: #666666;
      }
      .single-plan-price {
        margin-top: 17px;
        h3 {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 120%;
          color: #0065ff;
        }
        p {
          color: #292929;
        }
      }
      .single-plan-btn {
        background: #e6f0ff;
        padding: 11px 22.5px;
        width: 100%;
        justify-content: center;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: #0065ff;
        margin-top: 17px;
        cursor: pointer;
      }
      .single-plan-btn:hover {
        background: #0065ff;
        color: #ffffff;
        text-decoration: none;
      }
    }
    .single-plan:hover {
      box-shadow: 2px 3px 9px rgba(169, 169, 169, 0.24);
    }
    .single-planss::after {
      content: "Limited Time Only";
      position: absolute;
      top: 20px;
      border-radius: 20px;
      right: 14px;
      background: red;
      color: white;
      padding: 5px 16px;
      transform: rotate(0deg);
      transform-origin: top right;
      z-index: 10;
      font-family: "Ubuntu", sans-serif;
      font-style: normal;
      font-size: 16px;
      white-space: nowrap;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
}

.featured-bottom {
  .features-list-item {
    padding: 16px 20px;
    margin-top: 2px;
    min-width: 303px;
    margin-left: 12px;
    li {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 120%;
      color: #292929;
      margin-bottom: 24px;
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
  .featured-plans {
    margin-top: 2px;
    .features-list-item {
      padding: 16px 20px;
      margin-top: 2px;
      min-width: 303px;
      margin-left: 12px;
      li {
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 120%;
        color: #292929;
        margin-bottom: 24px;
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
    .single-plan {
      min-width: 303px;
      max-width: 303px;
      .features-list-item {
        padding: 0;
        margin-top: 0;
      }
    }
  }
}

.cancellation {
  padding-bottom: 100px;
}

.report {
  .report-left {
    .report-card {
      background: #ffffff;
      padding: 24px 24px 28px;
      margin: 0 15px 20px 15px;
      .heading {
        margin-bottom: 15px;
        span {
          font-weight: 500;
          font-size: 24px;
        }
      }
    }
    .report-card-premium {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 28px;
      margin: -45px 15px 20px 15px;
      background: #ffffff;
      position: relative;
    }
    .report-card-premium::before {
      content: "";
      height: 40px;
      position: absolute;
      left: 0;
      right: 0;
      top: -35px;
      background: linear-gradient(0deg, #ffffff, transparent);
    }

    .reprot-left-top {
      .report-progress {
        border: 1px solid #e8f5ed;
        text-align: center;
        padding: 30px;
        span {
          height: 16px;
          border-radius: 10px;
          margin-bottom: 15px;
        }
        .report-progress-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 140%;
            color: #179748;
            padding: 4px 8px;
            background: #e8f5ed;
            display: inline;
            border-radius: 30px;
          }
          h1 {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #2e2e2e;
          }
        }
      }

      .result {
        margin-top: 25px;
        text-align: justify;
        h3 {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 140%;
          color: #3b3b3b;
        }
        p {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 140%;
          color: #626161;
          margin-top: 10px;
        }
      }
    }
    .reprot-left-bottom {
      .solution-list {
        display: flex;
        overflow-x: scroll;
        .single-solution {
          min-width: 380px;
          margin-right: 20px;
          max-width: 380px;
          .single-solution-top {
            padding: 30px 20px;
            h4 {
              font-family: "Ubuntu";
              font-style: normal;
              font-weight: 300;
              font-size: 20px;
              line-height: 140%;
              color: #ffffff;
            }
            p {
              font-family: "Ubuntu";
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 120%;
              color: #bfbfbf;
            }
          }
          .single-solution-bottom {
            background: #f5f5f5;
            padding: 20px;
            display: flex;
            img {
              width: 49px;
              border-radius: 50%;
              height: 49px;
              margin-right: 18px;
            }
            .single-solution-text {
              p:first-child {
                font-family: "Ubuntu";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 120%;
                color: #0d0d0d;
                margin-bottom: 4px;
              }
              p:last-child {
                font-family: "Ubuntu";
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 120%;
                color: #666666;
              }
            }
          }
        }
      }
      .solution-list::-webkit-scrollbar {
        width: 5px;
        height: 6px;
        background-color: #ffffff;
      }
      .solution-list::-webkit-scrollbar-thumb {
        background: #000;
        border-radius: 10px;
      }
    }

    .reprot-right-top {
      .report-purpose {
        h3 {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 120%;
          color: #0d0d0d;
          margin-top: 22px;
        }
        p {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 120%;
          color: #858585;
          margin-right: 10px;
          margin-bottom: 23px;
        }
      }
      .btns {
        display: flex;
        border-top: 1px solid #dedede;
        padding-top: 25px;
        // margin-top: 60px;
        .report-card-btn {
          cursor: pointer;
          display: flex;
          padding: 8px 12px;
          background: #e6f0ff;
          align-items: center;
          margin-right: 15px;
          img {
            margin-right: 10px;
          }
          p {
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
    }
    .reprot-progress-item {
      .report-progress-item-top {
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        p {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 140%;
          color: #2e2e2e;
        }
      }
      span {
        height: 10px;
        border-radius: 15.4348px;
      }

      .report-progress-item-bottom {
        margin-top: 25px;
        text-align: justify;
        p {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 140%;
          color: #000000;
          margin-bottom: 5px;
        }
        span {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 140%;
          color: #858585;
        }
      }
    }
  }
}
.xyzz {
  bottom: 0;
  position: absolute;
  margin-bottom: 40px;
  width: 100%;
  .report-card-btn {
    width: 80%;
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    background: #e6f0ff;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 4px;
    img {
      margin-right: 10px;
    }
    p {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
    }
  }
}

.reprot-compare-item {
  background: #ffffff;
  padding: 24px 24px 28px;
  margin: 0 15px 20px 15px;
  .bar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    div {
      width: 100%;
      height: 100%;
      padding: 25px;
    }
  }
  .report-progress-item-top {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    p {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      color: #2e2e2e;
    }
  }
  span {
    height: 10px;
    border-radius: 15.4348px;
  }

  .report-progress-item-bottom {
    margin-top: 25px;
    p {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 120%;
      color: #0d0d0d;
      margin-bottom: 5px;
    }
    span {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #858585;
    }
  }
}
.fixedBOx {
  width: 660px;
  position: fixed;
}
.complete-state {
  display: flex;
  height: 100vh;
}
.complete-state > img {
  width: 50%;
}
.roundbox {
  height: 20px;
  width: 180px;
  border-radius: 25px;
  display: inline-block;
}
.boxsa {
  display: inline-flex;
  p {
    padding-left: 12px;
    font-size: 24px;
    font-weight: 400;
    min-width: 78px;
  }
}

.contentCard2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 15px 0;
  p {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    color: #666666;
  }
  .packages {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    .packageName {
      span {
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #bfbfbf;
      }
      h5 {
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: #2e2e2e;
      }
      h6 {
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 120%;
        color: #2e2e2e;
        ul{
          list-style: inside;

        }
      }
    }
    .packageInfo {
      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        img {
          margin-right: 10px;
        }
        .details {
          span {
            display: block;
            line-height: 18px;
          }
          span:first-child {
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #292929;
          }
          span:last-child {
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            color: #292929;
          }
        }
      }
    }
  }
  .package {
    justify-content: space-between;
    padding-right: 50px;
    .packageName {
      span {
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #bfbfbf;
      }
      h5 {
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: #2e2e2e;
      }
      h6 {
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 120%;
        color: #2e2e2e;
        ul{
          list-style: inside;

        }
      }
    }
    .packageInfo {
      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        img {
          margin-right: 10px;
        }
        .details {
          span {
            display: block;
            line-height: 18px;
          }
          span:first-child {
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #292929;
          }
          span:last-child {
            font-family: "Ubuntu";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            color: #292929;
          }
        }
      }
    }
  }
}

.schedule {
}

.schedule-top {
  display: flex;
  justify-content: space-between;
}

.single-schedule {
  width: 49%;
  border: 1px solid #ebebeb;
  padding: 16px;
  margin-bottom: 11px;
}

.single-schedule p {
  font-size: 14px;
  color: #949494;
}

.single-schedule h5 {
  color: #2e2e2e;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  margin-top: 9px;
}

.upcoming-consultation {
  border: 1px solid #ebebeb;
  padding: 16px;
  display: flex;
  align-items: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: #0d0d0d;
}

.upcoming-consultation img {
  margin-right: 16px;
}

.assesment-info {
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 30px;
}

.assesment-widgets {
  margin-bottom: 10px;
  margin-left: 45px;
}

.assesment-widgets p {
  display: flex;
  align-items: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: #0d0d0d;
  margin-bottom: 2px;
}

.assesment-widgets h3 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #2e2e2e;
}

.assesment-widgets p svg {
  margin-right: 5px;
}

.bars {
  display: none;
}

.laptopphoto {
  img {
    height: 517px;
    padding-top: 0px;
  }
  display: flex;
  justify-content: start;
}


@media only screen and (min-width: 1897px) {
  .visibility-toggle {
    right: -50px; 
    top: 200px; 
  }
}

@media only screen and (max-width: 1440px) {
  // .quick2{
  //   padding-right: 260px;
  // }
  .featured-plan {
    overflow: scroll;
  }
  .featured-top {
    width: 1134px;
  }
  .featured-bottom {
    .features-list-item {
      width: 340px;
    }
    width: 1134px;
  }
  // .featured-top .css-11lq3yg-MuiGrid-root{
  //   display: block;
  // }
  // .featured-bottom .css-11lq3yg-MuiGrid-root{
  //   display: block;
  // }
}

@media only screen and (max-width: 1200px) {
  .quick2{
    padding-right: 260px;
  }
  .ptl {
    height: auto;
  }
  .quarterArea {
    flex-wrap: wrap;
  }
  .quarterArea .cardQuarterDashboard {
    width: 49%;
    margin-bottom: 20px;
  }
  .cardFullDashboard .txtCardFullDash {
    padding-right: 12px;
    width: 50%;
  }
  .cardFullDashboard .lty {
    width: 50%;
  }
  .assesment-widgets h3 {
    font-size: 20px;
  }
}

//Assesment Section Ends here
@media only screen and (max-width: 999px) {
  .css-1oqkubv-MuiGrid-root {
    flex-basis: 100%;
  }
  .quick2{
    padding-right: 0px;
    
  }
  .onbUserProfileBtn {
    display: none;
  }
  .onbar .card {
    margin-left: 20px;
  }
  .onbar {
    padding-right: 20px;
  }
  //SignUp section
  .bg {
    display: none;
  }
  .cont1 {
    padding-top: 20px;
    width: 80%;
  }
  .quarterArea .cardQuarterDashboard {
    width: 100%;
  }
  .cardFullDashboard {
    display: block;
  }
  .cardFullDashboard .txtCardFullDash {
    width: 100%;
    padding-right: 12px;
  }
  .cardFullDashboard .lty {
    width: 100%;
  }
  .assesment-widgets {
    margin-left: 0;
    margin-right: 45px;
  }
  .plan-txt2 {
    padding: 30px;
  }
  .plancta {
    padding-top: 32px;
  }
  //signup Section ends
}

@media only screen and (max-width: 768px) {
  .assesmentUser{
    display: none;
  }
  .toggle-container3 {
    padding-bottom: 24px;
  }
  .quick{
    padding-bottom: 25px;
  }
  .quick2{
    padding-right: 0px;
    // padding-left: 50px;
  }
  .responsive-form{
    width: 100%;
  }
  .loaderscreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 94vh;
    padding-left: 0px;
  }
  .success-icon {
    font-size: 48px;
  }
  .custom_container {
    width: 90%;
    margin-left: 0px;
  }
  // .onPScroll .onpInput .onin {
  //   padding-left: 0;
  //   width: 100%;
    
  // }
  .onbBtn button {
    min-width: 50%;
  }
  .onbBtn {
    display: flex;
  }
  .bars {
    display: block;
  }
  .responsive-form {
    position: relative;
    padding-bottom: 120px;
    width: 100%;
    
  }
  .onbBtn {
    position: absolute;
    bottom: 0;
    margin-bottom: 36px;
    z-index: 1;
  }
  .progressOnb .cds--progress {
    width: 100%;
    overflow-x: scroll;
  }
  .complete-state .onbBtn {
    position: relative;
    bottom: 0;
    margin-bottom: 36px;
    z-index: 1;
  }
  .complete-state .onbBtn button {
    margin-top: 20px;
  }
  .complete-state {
    flex-direction: column-reverse;
    .onbUserProfileBtn {
      margin: 0;
      justify-content: center;
      height: 100%;
    }
  }
  .complete-state > img {
    width: 100%;
    height: 50%;
  }
  .PortalLayout {
    width: 0;
    visibility: hidden;
    opacity: 0;
  }
  .PortalLayoutOpen {
    width: 100%;
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transition: 0.2s;
  }
  .innerPortal {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
  .portalHeader {
    width: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
  }
  .portalHeader .textHeader {
    padding: 0;
  }
  .portalHeader .icn {
    padding: 0;
  }
  // .PortalLayout {
  //     width: 50%;
  //     visibility: visible;
  //     opacity: 1;
  //     z-index: 999;
  // }
  .mbls {
    display: block;
  }
  .xyzz {
    width: 100%;
    .report-card-btn {
      width: 95%;
    }
  }
  .cardFullDashboard .txtCardFullDash {
    width: 100%;
    padding: 20px;
  }
  .complete-state .onbUserProfileBtn {
    margin: 0;
    justify-content: center;
    height: 100%;
    padding: 40px 20px 20px 20px;
  }
}

@media only screen and (max-width: 479px) {
  .lti {
    display: none;
  }
  .bgf {
    .barCOmpare {
      width: auto;
      padding: 20px;
      overflow-x: auto; // Enables horizontal scrolling
      white-space: nowrap; // Prevents content from wrapping
      max-width: 100%; // Ensures the div doesn't exceed the viewport width
      .chartContainer {
        min-width: 600px;
      }
    }
  }
  .services {
    height: 65vh;
    overflow-y: scroll;
  }
  .fghz {
    display: block;
    .fg {
      margin-bottom: 12px;
    }
  }
  .assesmentqsection {
    padding-bottom: 0px;
  }
  .xyzz {
    margin-right: 20px;
    width: 90%;
    .report-card-btn {
      width: 100%;
    }
  }
  .contentCard2 {
    .package {
      padding-right: 0px;
      .packageName {
        span {
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          color: #bfbfbf;
        }
      }
      .packageInfo {
      }
    }
  }
  .assesmentBottom {
    display: block;
  }
  .assesmentbottomleft {
    width: 80%;
    margin-bottom: 20px;
  }
  .assesmentbottomright {
    width: 100%;
    margin: 15px 0;
    justify-content: flex-start;
  }
  .assesmentqsection .assMentQcard .qsbtn {
    display: block;
  }
  .assesmentqsection .assMentQcard .qsbtn .qsbt {
    padding: 0;
    margin-top: 15px;
  }
  .btnss {
    justify-content: center;
    padding: 10px;
    min-width: 100%;
  }
  .cont {
    padding: 0 15px;
  }
  .cont1 {
    width: 100%;
  }
  .cds--progress-step-button {
    padding: 10px 0;
  }
  .onbUserProfileBtn {
    padding-top: 40px;
  }
  .plancta {
    display: block;
  }
  .planctaCard {
    margin-bottom: 15px;
  }
  .planctaCard:last-child {
    margin-bottom: 0;
  }

  .laptopphoto img {
    width: 100%;
    padding-top: 24px;
  }
}
